.footer-top {
  min-height: 80px;
  background: #39b5d1;
  z-index: 999;
}

.footer {
  min-height: 10px;
  padding: 20px 10px;
  background: #000066;
  z-index: 99999;
}

.footer-bottom {
  min-height: 30px;
  background: #000066;
  color: white;
  display: flex;
  padding-bottom: 10px;
  border-top: 1px solid white;
  transition: all 0.2s ease-in-out;
}
.footer-bottom img {
  transition: all 0.2s ease-in-out;
}
.footer-bottom img:hover {
  transform: translateY(-5px);
  transition: all 0.2s ease-in-out;

  /* box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2); */
}

.termsandcond {
  font-family: "Montserrat", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.08em;
}
.copyright {
  font-family: "Montserrat", serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  letter-spacing: 0.08em;
}

.Terms {
  font-family: "Montserrat", serif;
  background-color: white;
  max-width: 60vw;
  margin: 20px auto;
  padding: 25px;
}

.Terms h1 {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin: 20px 0px;
}
.Terms h2 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin-top: 10px;
}

.Terms h3 {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin-top: 10px;
}
.Terms h4 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin-top: 20px;
}
.Terms h5 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin-top: 20px;
}
.Terms h6 {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height:28px;
  margin-top: 20px;
}

.Terms p {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
  letter-spacing: 1px;
  line-height: 1.7em;
  color: #212529;
}

.Terms li {
  margin-left: 30px;
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
  letter-spacing: 1px;
  line-height: 1.7em;
  color: #212529;
}

.footer h5 {
  font-family: "Montserrat", serif;
  font-size: 17px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
}

.footer-list {
  padding-left: 20px;
  list-style-type: none;
  margin: 0;
}

.footer-list li {
  margin-bottom: 8px;
}

@media (max-width: 1024px) {
  .slider-div {
    width: 80vw; /* Tablet view adjustments */
    margin: 20px auto;
  }
  .slider-div2 {
    width: 80vw; /* Default for web view */
  }
}
@media (max-width: 600px) {
  .termsandcond {
    font-family: "Montserrat", serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.08em;
  }
  .copyright {
    font-family: "Montserrat", serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: 0.08em;
  }

  .Terms {
    font-family: "Montserrat", serif;
    max-width: 90vw;
    margin: 20px auto;
  }
}






/* Social Media  */
/* Social Media Parent container */
.social-menu {
  z-index: 99999;
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 10px;
}

/* Toggle button */
.menu-toggle {
  background-color: #000066 !important;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.365);
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

/* Menu container */
.menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.5s ease-in-out;
  background-color: #000066;
  border: 2px solid white;
  border-radius: 40px;
  padding: 5px 0px;
}

/* Show menu when active */
.menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Icon hover effect */
.menu .MuiIconButton-root:hover {
  transform: scale(1.1);
  transition: all 0.2s ease-in-out;
}
