.product-div {
  margin-top: 2%;
  padding:0px;
  /* background: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(1, 8, 56, 0.115) 100%
  ); */
}
#gallery-div{
  background: white;
  padding: 40px 0px 0px;
}

.product-div h4 {
  color: #39b5d1;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;
  letter-spacing: 1px;
}
.product-div h3 {
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom:7px;
  text-align: center;
  letter-spacing: 1px;
}

.product-div p {
  font-family: "Montserrat", serif;
  color: #212529;
  font-size: 19px;
  font-weight: 400;
  line-height:28px;
  letter-spacing: 1px;
  text-align: center;

}

.slider-div {
  width: 55vw; /* Default for web view */
  margin: 10px auto 20px;
}

.slider-div p {
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 500;
  line-height: 21.78px;
  text-align: center;
  margin-top: 20px;
  letter-spacing: 1px;
}

.slider-div2 {
  width: 91vw; /* Default for web view */
  margin: 0px auto 30px;
}

.slider-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.slider-controls button {
  color: #000066;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.Product-head1 {
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #212b36;
  letter-spacing: 1px;
}
.product-para1 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height:28px;
  letter-spacing: 1px;
  margin-top: 15px;
  color: #212529;
}

@media (max-width: 1024px) {
  .slider-div {
    width: 100vw; /* Tablet view adjustments */
    margin: 0px auto;
  }
  .slider-div2 {
    width: 80vw; /* Default for web view */
    margin: 20px auto;
  }
  .product-div{
    padding: 15px 0px;
  }

  .product-div h3 {
    font-size: 32px;
  }

  .product-div h3 {
    font-size: 32px;
  }

  .product-div p {
    font-family: "Montserrat", serif;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
    line-height:28px;
    letter-spacing: 1px;
    text-align: center;
  }


}


