
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative; /* To position spinner and image on top of each other */
  background: transparent;
}

.loader {
  width: 200px;
  height: 200px;
  display: inline-block;
  border-top: 10px double #0066ff; /* Transparent top border to create the ring */
  border-right: 10px double #00ffcc; /* Transparent right border to create the ring */
  border-bottom: 10px double #0066ff; /* Transparent top border to create the ring */
  border-left: 10px double #00ffcc; /* Transparent right border to create the ring */
  border-radius: 50%;
  animation: rotation 1.5s linear infinite;
}

.loader-logo {
  position: absolute; /* Position logo at the center */
  width: 100px; /* Adjust logo size */
  height: 100px; /* Adjust logo size */
  object-fit: contain;
  z-index: 1; /* Ensure logo stays above the spinner */
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
