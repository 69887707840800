/* Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f4f4f4;
}

.navbar {
  margin: auto;
  width: 90vw;
  height: 100px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.logo {
  position: relative;
  right: 25px;
  overflow: hidden;
}

.nav-links {
  display: flex;
  gap: 2rem;
  position: relative;
  right: 80px;
}

.nav-link {
  position: relative;
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.5s ease;
  padding: 10px;
}

.nav-link:hover {
  color: #000066;
  font-size: 18px;
  /* border-radius: 20px; */
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #39b5d1;
  transform: translateY(-3px);

}


.nav-link.active {
  color: #000066;
  font-size: 18px;
  /* border-radius: 20px; */
  transition: all 0.1s ease;
  position: relative;
  bottom: 1px;
  border-bottom: 1px solid #39b5d1;
}

/* Action Button */
.action-btn {
  position: relative;
  background: transparent;
  border: none;
  color: #000066;
  background-color: white;
  padding: 5px 15px;
  border-radius: 20px;
  margin: 0px 10px;
}

.btn-text {
  position: relative;
  z-index: 1;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}



#signin-btn {
  background-color: #000066;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  line-height:28px;
  letter-spacing: 1px;
  color: #ffffff;
  border-radius: 100px;
  padding: 6px 15px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-top: 2%;
  letter-spacing: 1px;
}

#mobile{
  background-color: #ffffff;
  color: #000066;
 
}

#signin-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2);
}


#reg-btn {
  border: 2px solid #39b5d1;
  background: #39b5d1;
  color: white;
}

/* Mobile Toggle */
.mobile-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
}

.mobile-toggle span {
  display: block;
  position: relative;
  left: 20px;
  width: 30px;
  height: 4px;
  background: black;
  margin: 5px 0;
  transition: all 0.3s ease;

}


.mobile-toggle-dash {
  display: none;
  background: transparent;
  border: none;
  
  cursor: pointer;
}

.mobile-toggle-dash span {
  display: block;
  position: relative;
  left: 20px;
  width: 30px;
  height: 4px;
  background: white;
  margin: 8px 0;
  transition: all 0.3s ease;
 
}


/* Mobile Menu */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #1e293b;
  backdrop-filter: blur(10px);
  padding: 2rem;
  transform: translateX(-100%);
  transition: all 0.3s ease;
  z-index: 999;
}

.mobile-menu.active {
  transform: translateX(0);
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.close-menu {
  background: transparent;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: relative;
}

.close-icon {
  position: absolute;
  width: 100%;
  height: 2px;
  background: white;
  transform: rotate(45deg);
}

.close-icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: white;
  transform: rotate(90deg);
  left: 0px;
}

.menu-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-link {
  display: flex;
  align-items: center;
  color: #94a3b8;
  text-decoration: none;
  font-family: "Montserrat", serif;
  font-size: 1.1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
}

.menu-link:hover,
.menu-link.active {
  color: white;
  background: linear-gradient(45deg, #60a5fa20, #a78bfa20);
}

.menu-footer {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  }

.mobile-action-btn {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(45deg, #60a5fa20, #a78bfa20);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.mobile-action-btn:hover {
  background: linear-gradient(45deg, #60a5fa40, #a78bfa40);
}

/* Blurry Background Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  
  backdrop-filter: blur(8px);
  z-index: 1000;
}

/* Login Modal */
.login-modal {
  position: fixed;
  /* top: 10%; */
  right: 20px;
  transform: translate(50%, -50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  height: 300px;
 
}




/* Responsive Design */
@media (max-width: 1400px) {
  .nav-links,
  .nav-actions {
    display: none;
  }

  .mobile-toggle {
    display: block;
    position: relative;
    right: 20px;

  }

  .mobile-toggle-dash{
    display: block; 
  }

  .navbar {
    padding: 0 1rem;
  }
}

@media (max-width: 1000px) {
  .modal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Optional: Adds a blurry overlay */
    backdrop-filter: blur(8px); /* Optional: Adds a blur effect */
    z-index: 1000;
  }

 
}
