/* 
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Manrope:wght@200..800&family="Montserrat", serif:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family="Montserrat", serif:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lora:ital,wght@0,400..700;1,400..700&family=Manrope:wght@200..800&family="Montserrat", serif:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

/* Roboto – Balanced and widely used in web design.
Montserrat – Stylish and contemporary.
Lato – Clean and professional.
Merriweather – Readable and classic.
EB Garamond – Traditional and refined. */




.slider-container {
  position: relative;
  margin: auto;
  padding: 10px 0px;
  width: 90vw;
  min-height: calc(100vh - 100px);
  overflow: hidden;

  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content (optional) */
}

.slider-background {
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
}

.slider-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.5s ease-in-out;
}
.fade-in {
  opacity: 1;
  /* transform: translateX(0); */
}

.fade-out {
  opacity: 0;
  /* transform: translateX(-100%); */
}

.empty-right {
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
  overflow: hidden; /* Ensure image doesn't overflow */
}

.empty-right img {
  max-width: 100%; /* Ensure the image stays within the grid */
  max-height: 100%; /* Ensure the image doesn't exceed grid height */
  object-fit: contain; /* Maintain aspect ratio and fit within the grid */
}

.text-left {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  padding: 10px 0px;
  border-radius: 8px;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  position: relative;
}
.slider-bubbles {
  opacity: 0.1;
  position: absolute;
  left: 0px;
}

.slider-head1 {
  font-family: "Montserrat", serif;
  font-size: 56px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 1px;
  /* text-transform: capitalize; */
  line-height: 1em;
}


.slider-para1 h2{
  font-family: "Montserrat", serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 1em;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.slider-para1 p{
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  line-height:28px;
  letter-spacing: 1px;
}

.slider-para1 li{
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  line-height:28px;
  letter-spacing: 1px;
  position: relative;
  left: 20px;
}

.slider-head {
  color: white;
  font-family: "Montserrat", serif;
  font-size: 54px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 1px;
  /* text-transform: capitalize; */
  line-height: 1em;
}

.slider-para {
  color: white;
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  text-align: justify;
  margin: 10px 0px;
  line-height:28px;
  letter-spacing: 1px;
}

.slider-btn {
  background-color: #000066;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height:28px;
  letter-spacing: 1px;
  color: #ffffff;
  border-radius: 100px;
  padding: 15px 40px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-top: 2%;
  letter-spacing: 1px;
}

.slider-btn:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2);
}

@media (max-width: 500px) {
  .slider-container {
    width: 100vw;
  }

  .slider-head1 {
    font-size: 36px;
    text-align: center;
    max-width: 100%;
    margin: auto;
    font-weight: 700;
    text-align: center;
    line-height: 1.1em;
  }
  .slider-para1 {
    font-size: 18px;
    max-width: 100%;
    text-align: center;
  }

  .slider-head2 {
    font-size: 30px;
    text-align: center;
    max-width: 100%;
    margin: auto;
    font-weight: 600;
  }
  .slider-para2 {
    font-size: 18px;
    max-width: 100%;
    text-align: center;
  }
  .slider-head3 {
    font-size: 30px;
    text-align: center;
    max-width: 100%;
    margin: auto;
    font-weight: 600;
  }
  .slider-para3 {
    font-size: 18px;
    max-width: 100%;
    text-align: center;
  }

  .text-left {
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .slider-btn {
    margin: auto;
    margin-top: 15px;
    padding: 10px 30px;
  }
}

.landing-slider-image {
  width: 100%;
  height: calc(100vh - 100px);
  object-fit: cover; /* Ensure image covers the entire div */
}
.home-slider {
  width: 100%;
  height: calc(100vh - 100px);
  background-color: #39b5d1;
  background-image:""
}
.service-home-slider {
  height: calc(100vh - 100px);
  background-color: #39b5d1;
  background-image:""
}
.about-home-slider {
  height: calc(100vh - 100px);
  background-color: #39b5d1;
  background-image:""
}

@media (max-width: 1280px) {
  .slider-head {
    font-family: "Montserrat", serif;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
    line-height: 1em;
    margin: auto;
  }

  .slider-para {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    margin: 10px 0px;
    line-height:28px;
    letter-spacing: 1px;
  }
}

.landing-slider-image2 {
  display: none;
}

#landing-slider-btn{
  color: white;
  background-color: #000066;
  transition: all 0.5s ease-in-out;

}
#landing-slider-btn:hover{
  transition: all 0.5s ease-in-out;
}

@media (max-width: 1025px) {
  .landing-slider-image {
    height: 70vh;
  }
  .landing-slider-image2 {
    height: calc(100vh - 100px);
    width: 100%;
    display: block;
    object-fit: cover;
    
  }
 
 
  .home-slider {
    height: auto; /* Adjust for smaller screens */
    max-height: auto;
    min-height: 70vh;
    background-color: #39b5d1 ;
    background-image: url("../Images/Home/landingbg.png");
    background-size: 150% 100%;
  }
  .service-home-slider {
    height: auto;
    max-height: auto;
    min-height: 70vh;
    background-color: #39b5d1;
    background-image: url("../Images/Home/landingbg.png");
    background-size: 150% 100%;
  }
  .about-home-slider {
    height: auto;
    max-height: auto;
    min-height: 70vh;
    background-color: #39b5d1;
    background-image: url("../Images/Home/landingbg.png");
    background-size: 150% 100%;
  }

  .slider-head {
    font-family: "Montserrat", serif;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    /* text-transform: capitalize; */
    line-height: 1em;
    margin: auto;
  }

  .slider-para {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    text-align: center;

    margin: 10px 0px;
    line-height:28px;
    letter-spacing: 1px;
  }

  .parent-container {
    display: flex;
    justify-content: center;
  }

  .slider-btn {
    margin: auto;
    margin-top: 15px;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  #landing-slider-btn{
    color: #000066;
    background-color: white;
  }
}
