.service-div {
  background-image: url("../Images/Home/landingbg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 300px;
}

.slider-img {
  position: relative;
  top: 6px;
  left: 30px;
  width: 100px;
  cursor: pointer;
}

.slider-play-span {
  position: relative;
  top: 30px;
  left: 50px;
  cursor: pointer;
}

.slider-actions {
  display: flex;
  flex-direction: row; /* Stack items vertically by default */
}

.capabilities {
  position: relative;
  /* min-height: 400px; */
  z-index: 1; /* Ensure content is above the background */
}

.capabilities::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/Home/networkpic.jpg");
  background-size: 100%;
  background-position: top;
  background-repeat: repeat;
  opacity: 0.1; /* Set the background opacity */
  z-index: -1; /* Place the background behind the content */
}

.ourCapabilities {
  width: 80vw;
  margin: auto;
  padding: 30px 0px
}

.ourCapabilities-head1 {
  font-family: "Montserrat", serif;
  font-size: 25px;
  font-weight: 700;
  /* text-align: left; */
  color: #000066;
}

.ourCapabilities-head2 {
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  
  line-height: 44px;
  margin-bottom: 5px;
}

.ourCapabilities-para {
  font-family: "Montserrat", serif;
  color: #212529;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.05em;
  max-width: 96%;
  text-align: justify;
}

.capability-box-en,
.capability-box-ar {
  background: #39b5d1;
  color: white;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;  /* Centers items horizontally */
  justify-content: flex-end;  /* Centers items vertically */
  text-align: center;  /* Centers text inside */
  min-height: 400px;  
  border: 10px solid white;
}

.capability-box-head {
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 700;
  margin: 10px 0px;
  color: white;
}

.capability-box-para {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6em;
  /* text-align: right; */
}

.ourService {
  width: 80vw;
  margin: 20px auto 0px;
  position: relative;
}

.ourService-head1 {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0.02em;
  color: #000066;
  margin: 30px auto;
}

.ourService-Subhead {
  margin: 20px 0px;
  color: #222222;
  font-family: "Montserrat", serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
}

.ourService-para {
  color: #212529;
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
}

.service-slider {
  /* height: 600px; */
  /* overflow-y: auto; */
  position: relative;
  /* top: 90px; */
  z-index: 1;
}

.service-slider h4 {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
}

.service-slider p {
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;

}

.serviceTestimonial {
  margin: 10px auto;
}

.service-testimonial-head1 {
  color: #240161;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 22.6px;
  letter-spacing: 0.23em;
  text-align: left;
  margin: 0px 20px;
}

.service-testimonial-head2 {
  color: #240161;
  font-family: "Montserrat", serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 76.8px;
  text-align: left;
  margin: 0px 20px;
}
.service-testimonial-span {
  color: transparent;
  border-bottom: 3px solid #240161;
  position: relative;
  bottom: 14px;
  margin-right: 20px;
}

.Service-Testimonial-box-para {
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.testimonial-slider-head {
  color: white;
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
}

.testimonial-slider-para {
  color: white;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
}
.blogDetails-div {
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.blogDetails-div p,
.blogDetails-div h2,
.blogDetails-div h3{
  color: #212529;
  padding: 5px;
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
}
.blogDetails-div strong{
  color: #000066;
}
.blogDetails-div li{
  position: relative;
  color: #212529;
  left: 20px;
  padding: 5px;
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
}
@media (max-width: 1024px) and (max-height: 600px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 350px !important; /* Force override */
    padding: 30px !important; /* Adjust if necessary */
  }
}

@media (max-width: 1280px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 370px !important; /* Force override */
    padding: 20px !important; /* Adjust if necessary */
  }
}
@media (max-width: 1471px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 370px !important; /* Force override */
    padding: 20px !important; /* Adjust if necessary */
  }
}
@media (max-width: 1527px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 370px !important; /* Force override */
    padding: 20px !important; /* Adjust if necessary */
  }
}
@media (max-width: 1815px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 450px;
    padding: 40px 20px;
  }
  
}
@media (max-width: 1200px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 320px;
  }
}
@media (max-width: 1000px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 370px;
  }
}
@media (max-width: 890px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 250px;
  }
}
@media (max-width: 889px) {
  .capability-box-en,
  .capability-box-ar {
    min-height: 300px !important;
    padding: 40px !important;
  }
}

@media (max-width: 600px) {
  .service-slider {
    position: relative;
    top: 0px;
  }
  
  .ourCapabilities {
    width: 80vw;
    margin: auto;
    padding: 20px 0px 0px
  }

  .capability-box-en,
  .capability-box-ar {
    min-height: 370px; /* Ensure consistent height for the box */
  }

  .slider-actions {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .slider-img {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100px;
  }
  .ourService {
    width: 100vw;
    margin: 0px auto;
    position: relative;
    padding: 20px;
  
  }

  .slider-play-span {
    position: relative;
    top: 0px;
    left: 0px;
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.44px;
    text-align: left;
    color: black;
  }

  .ourCapabilities-head2 {
    text-align: center;
  }

  .ourService-head1 {
    text-align: center;
  }
  
  .ourService-Subhead {
    text-align: center;
  }
  .ourService-para {
    text-align: center;
  }

  .ourCapabilities-para {
    text-align: center;
  }

  .blogDetails-div p,
.blogDetails-div h2,
.blogDetails-div h3{
  color: #212529;
  padding: 5px;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.blogDetails-div strong{
  color: #000066;
}
.blogDetails-div li{
  position: relative;
  color: #212529;
  left: 20px;
  padding: 5px;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.ourCapabilities-head1 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  color: #000066;
}
}
