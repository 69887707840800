.logindiv {
  background: #0037a4;
  height: 100vh;
  background-image: url("../Images/Signup/Group\ 1000001126.png");
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-div {
  /* width: 100vw; */
  /* min-height: 606px; */
  max-height: 80vh;
  overflow-y: auto;
  padding:50px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.signin-heading {
  font-family: "Montserrat", serif;
  font-size: 24px;
  margin: 10px;
}

.signin-para {
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin: 10px;
  color: #555;
}

.signin-label {
  font-family: "Montserrat", serif;
  font-size: 16px;
  color: #555;
  position: relative;
  bottom: 2px;
}

.text-field {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin: 30px 0px;
}

.remember-me {
  font-family: "Montserrat", serif;
  font-size: 16px;
  color: #555;
}

.remember-me input {
  margin-right: 5px;
  cursor: pointer;
}

.forgot-password {
  text-decoration: none;
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 16px;
  cursor: pointer;
}

.forgot-password:hover {
  text-decoration: underline;
}

.login-btn {
  padding: 20px;
  background: linear-gradient(90deg, #000066 0%, #000066 100%);
  width: 100%;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Montserrat", serif;
  font-size: 16px;
}

.login-btn:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
 
/* Login Modal */
.login-modal {
  position: fixed;
  /* top: 10%; */
  /* right: 0px; */
  transform: translate(50%, -50%);
  background: white;
  border-radius: 10px;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  width: 90vw;


 
}
  
}
