.about-landing {
  background-image: url("../Images/Home/landingbg.png");
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 70vh;
  /* Flexbox for vertical and horizontal centering */
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content (optional) */
}

.about-landing2 {
  position: relative;
  z-index: 1; /* Ensure content is above the background */
}

.about-landing2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/Home/networkpic.jpg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0.1; /* Set the background opacity */
  z-index: -1; /* Place the background behind the content */
}

.about-first-head {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: #240161;
  letter-spacing: 1px;
  line-height: 1.8em;
}

.about-head1-hyphen {
  border-top: 3px solid #240161;
  width: 20px;
  height: 20px;
  color: transparent;
  position: relative;
  top: 15px;
  margin-right: 20px;
}

.about-landing2 h4 {
  font-family: "Montserrat", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 45px;
  text-align: left;
  color: #000066;
  /* max-width: 600px; */
}

.about-landing2 p {
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height:28px;
  text-align: justify;
  color: #212529;
  /* max-width: 600px; */
}

.about-landing3 {
  width: 80vw;
  margin: auto;
  padding: 0px 20px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.about-slider-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.about-landing-slider-head {
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
}

.about-landing-slider-para {
  color: #2c2c2c;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
}

.about-landing-slider-date {
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  margin: 20px;
}

.about-social {
  width: 80vw;
  min-height: 200px;
  margin: 0px auto;

}
.about-social-box {
  width: 79vw;
  min-height: 400px;
  background: #000066;
  background-image: url("../Images/About/Frame\(2\).png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
  margin: auto;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center content vertically */
  padding-left: 30px; /* Add left padding to position content */
}

.about-social-box p {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height:1.2em;
    text-align: left; /* Ensure text is left-aligned */
  color: white; /* Ensure visibility against dark background */
  margin: 10px 40px; /* Remove any default margin */
}

.about-social-box button {
  width: 180px;
  padding: 12px;
  background-color: white;
  color: #000066;
  border: none;
  border-radius: 40px;
  margin:10px 40px;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 500;
  transition:0.5s ease-in-out;
  cursor: pointer;
}
.about-social-box button:hover{
  transform: translateY(-5px);
  /* box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2); */
}

.about-social h4 {
  font-family: "Montserrat", serif;
  font-size: 64px;
  font-weight: 700;
  line-height:55px;
  text-align: center;
  color: #000066;
  margin-bottom: 20px; /* Adds spacing below the heading */
}

@media (max-width: 1600px) {

  .about-landing {
    background-size: cover;
    background-position: bottom;
  }

}

@media (max-width: 500px) {
  .about-first-head {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #240161;
    letter-spacing: 1px;
    line-height: 1.8em;
  }
}