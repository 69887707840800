.sidebar-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.sidebar {
  width: 300px;
  height: 100vh;
  background-color: darkblue;
  color: white;
  transition: width 0.5s;
  overflow: hidden;
  padding: 10px 0;
  z-index: 1;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebar.collapsed .menu li a span {
  display: none;
}

.sidebar .toggle-btn {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 20px;
  position: relative;
  left: 10px;
  padding: 10px;
  cursor: pointer;
}

.sidebar ul.menu {
  list-style: none;
  padding-top: 20px;
}

.sidebar ul.menu li {
  padding: 0;
  text-align: left;
}

.sidebar ul.menu li a {
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 15px;
  transition: background-color 0.3s;

  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  letter-spacing: 0.07em;
}

.sidebar ul.menu li a:hover,
.sidebar ul.menu li a.active {
  background-color: white;
  color: black;
}

.menu i {
  font-size: 18px;
  position: relative;
  left: 5px;
}

.menu span {
  margin-left: 5px;
}

.dash-content {
  width: 100%;
  height: 100vh;
  /* border: 2px solid red; */
}

@media (max-width: 500px) {
  .dash-content {
    width: calc(100% - 60px);
    position: fixed;
    right: 0px;
  }
}
