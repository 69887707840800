.project-div {
  background: #000066;
  color: white;
  padding: 30px 0px;
  width: 90vw;
  margin: auto;
  /* Flexbox for vertical centering */
  display: flex;
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content (optional) */
}

.project-head {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: px;
  margin-bottom: 7px;
  text-align: left;

}
.project-para {
  font-family: "Montserrat", serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  /* max-width: 80%; */
}
.project-btn {
  background: #39b5d1;
  padding: 10px 40px;
  border-radius: 50px;
  border: none;
  color: white;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  width: 200px;
}

.project-head1 {
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  color: #000066;
  margin: 10px 0px;
  letter-spacing: 1px;
}

.project-para1 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height:28px;
  letter-spacing: 1px;
  /* margin-top: 15px; */
  color: #212529;
  
}

@media (max-width: 1600px) {
  .project-para {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    line-height:28px;
    text-align: left;
  }
  .project-para1 {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 400;
    line-height:28px;
    letter-spacing: 1px;
    /* margin-top: 15px; */
    color: #212b36;
    
  }
}

@media (max-width: 500px) {
  .project-head {
    text-align: center;
    font-size: 32px;
  }
  .project-para {
    text-align: center;
    max-width: 100%;
  }
  .project-btn {
    text-align: center;
    display: none;
  }

  .project-div {
    padding: 30px 0px 0px;
  }
}
