.landing-div {
  /* background-image: url("../Images/Home/landingbg.png"); */
  background-color: #39b5d1;
  background-size: 100% 90%;
  background-position: top;
  background-repeat: no-repeat;
}

.main-div {
  width: 90vw;
  margin: 0 auto;
}

.landing2 {
  position: relative;
  background-color: white;
}


.fallback-loader-container{
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.224);
  backdrop-filter: blur(5px);
  z-index: 9999;

}


.landing2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../Images/Home/networkpic.jpg") top/contain no-repeat;
  opacity: 0.1; /* Adjust the opacity here */
  z-index: 1; /* Ensure it stays below the content */
}

.landing2 > * {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
}

.whoarewe-div {
  width: 90vw;
  margin: auto;
  padding: 30px 0px;
}

.whoarewetextdiv {
  margin: 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}


.whoarewe-head {
  font-family: "Montserrat", serif;
  color: #0F1111;
  font-size: 20px;
  font-weight: 500;
  line-height:28px;
  letter-spacing: 1px;
  text-align: left;
  margin-bottom: 10px;
}

#whoarewe-head4{
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height:1em;
  margin-bottom:7px;
  
  letter-spacing: 1px;
}

.whoarewe-head4 {
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height:1em;
  margin-bottom:7px;
  text-align: left;
  letter-spacing: 1px;
}

.whoarewe-para {
  font-family: "Montserrat", serif;
  color: #212529 ;
 
  font-size: 19px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 1px;
  text-align: justify;
  


  /* max-width: 96%; */
 
}
.error-text{
  font-family: "Montserrat", serif;
  color: red;
  font-size: 14px;
  font-weight: 400;
}

.whoarewe-btn {
  background-color:#000066;
  font-family: "Montserrat", serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height:28px;
  letter-spacing: 1px;
  color: #ffffff;
  border-radius: 100px;
  padding: 8px 20px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin-top: 2%;
  letter-spacing: 1px;
}

.whoarewe-btn:hover,
.whoarewe-btn:active {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2);
}

.whoarewe-btn-sm {
  width: 130px;
  background: #000066;
  border: 0;
  border-radius: 20px;
  font-weight: 500;
  color: white;
  padding: 10px 0;
  text-transform: uppercase;
  margin: 10px auto;
  position: relative;
  bottom: 15px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.whoarewe-btn-sm:hover,
.whoarewe-btn-sm:active {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.2);
}



.gallery-div {
  width: 90vw;
  margin: auto;
}

.gallery-img {
  transition: 1.5s ease-in-out all;
  scale: 1;
}
.gallery-img:hover {
  transition: 0.5s ease-in-out all;
  scale: 1.1;
}

.video-div {
  width: 90vw;
  margin: auto;
}
.video-head1 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.38px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
}
.video-head2 {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom:7px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
}
.video-para1 {
  font-family: "Montserrat", serif;
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 1px;
  margin: 10px 20px 35px;
}

.video-white-div {
  margin: 0 auto;
  width: 90%;
  background: white;
  border-radius: 10px;
  padding: 40px 30px 0px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.testimonial-div {
  width: 80vw;
  margin: auto;
  padding: 30px 0px 0px;
}
.About-testimonial-div{
  width: 80vw;
  margin: auto;
}
.testimonial-div h3 {
  margin: 0px auto 10px;
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: center;
  letter-spacing: 1px;
}
.About-testimonial-div h3 {
  margin: 0px auto 10px;
  color: #000066;
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 58.51px;
  text-align: center;
  letter-spacing: 1px;
}

.testimonial-head {
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: center;
  color: #3a4f39;
  letter-spacing: 1px;
}
.testimonial-para {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;

  letter-spacing: 1px;
  width: 70%;
  margin: 10px auto;
}

.Sponsor-div {
  height: 100px;
  background: #000066;
  margin: 0 auto;
  padding: 30px 0;
  overflow-x: none;
}
.sponsor-slider {
  width: 90vw;
  margin: auto;
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Pagination-head {
  font-family: "Montserrat", serif;
  color: #212b36;
  position: relative;
  margin-top: 30px;
}

@media (max-width: 1600px) {
  .landing-div {
    background-size: cover;
    background-position: bottom;
  }
  .whoarewe-para {
    max-width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height:28px;
  }
}
@media (max-width: 950px) {
  .main-div {
    max-width: 100vw;
  }
  .whoarewetextdiv {
    margin: 20px 0px;
    text-align: center;
  }
  .whoarewe-btn {
    margin: auto;
  }

  
  .whoarewe-head4 {
    text-align: center;

  }
  .whoarewe-head{
    max-width: 100%;
    font-size: 16px;
    line-height:28px;
  }
  .whoarewe-para {
    max-width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height:28px;
  }
  .whoarewe-div {
    padding: 20px 0px 0px;
  }
  .testimonial-div {
    margin: auto;
    padding: 30px 0px 0px
  }


  .video-head1 {
    font-family: "Montserrat", serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: center;
    color: white;
  }
  .video-head2 {
    font-family: "Montserrat", serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    margin-bottom:7px;
    text-align: center;
    color: white;
  }
  .video-para1 {
    font-family: "Montserrat", serif;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: 0.05em;
    margin: 0px 20px 35px;
  }
  .video-white-div {
    margin: 0 auto;
    width: 96%;
    background: white;
    border-radius: 10px;
    padding: 40px 10px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .About-testimonial-div h3 {
    margin: 0px auto;
    color: #000066;
    font-family: "Montserrat", serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 58.51px;
    text-align: center;
  }
  

}
