.dashnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  transition: all 0.4s ease;
  box-shadow: 0px 0px 40px 0px rgba(128, 128, 128, 0.25);
  background-color: #0037A4;

}

.dashnav-left {
  display: flex;
  gap: 30px;
}
.dashnav-left a {
  color: white;
  padding: 8px;
  transition: background-color 0.3s ease;
  font-family: "Montserrat", serif;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
}
.dashnav-left button {
  color: white;
  padding: 0px;
  transition: background-color 0.3s ease;
  font-family: "Montserrat", serif;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
}

.dashnav-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.profile-avatar .ProfileAvatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: relative;
  top: 13px;
}
.profile-avatar button {
  color: white;

  transition: background-color 0.3s ease;
  font-family: "Montserrat", serif;
  font-size: 22px;
  font-weight: 400;
  text-decoration: none;
  margin: 10px;
}

/* Mobile View */
.mobile-menu-btn {
  display: none;
  font-size: 2rem;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
  position: absolute;
  left: 20px;
}

.mobile-menu-dash a {
  color: white;
  text-decoration: none;
  padding: 8px;
  transition: background-color 0.3s ease;
  /* text-align: center; */
}
.mobile-menu-dash button {
  color: white;
  text-decoration: none;
  text-align: left;
  transition: background-color 0.3s ease;
}

.mmobile-menu-dash a:hover,
.mobile-menu-dash button:hover {
  /* background-color: #555; */
}
.mobile-menu-dash {
  display: none;
  position: absolute;
  top: 100px;
  left: 300px;
}

.dashlanding-h4 {
  font-family: "Montserrat", serif;
  font-size: 40px;
  font-weight: 600;
  text-align: left;
  margin: 40px 0px 0px;
}

.dashlanding-p {
  color: #212b36;
  font-family: "Montserrat", serif;
  font-size: 25px;
  font-weight: 500;
  text-align: left;
}
.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  padding: 40px;
  border-radius: 8px;
  color: white;
  flex: 1; /* Ensures equal height */
  
}

.violet {
  background: #6565ff;
}

.green {
  background: #00a1b4;
}

.pink {
  background: #e26c9d;
}

.blue {
  background: #276fd6;
}

.icon img {
  height: 35px;
  margin: 20px 0px;
}

.number {
  font-family: "Montserrat", serif;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
}
.number img {
  position: relative;
  top: 5px;
  right: 10px;
}

.text {
  font-family: "Montserrat", serif;
  font-size: 30px;
  font-weight: 500;
  text-align: left;
}

.reusableTableHead {
  font-family: "Montserrat", serif;
  font-size: 23px;
  font-weight: 700;
  text-align: left;
}

.dashlanding-one {
  width: 95vw;
  margin: auto;
}

.RecentNotification {
  min-height: 200px;
  border: 2px solid #e5e7eb;
  border-radius: 20px;
  margin: 20px auto;
}

.RecentNotification h3 {
  font-family: "Montserrat", serif;
  font-size: 30px;
  font-weight: 600;
  margin: 20px 20px;
}

.notificationlistbox {
  margin: 0px 20px 20px;
  border: 2px solid #aeaeae;
  border-radius: 20px;
  display: flex; /* Align items horizontally */
  align-items: flex-start; /* Align items at the top */
  padding: 30px; /* Add some space inside */
}

.notificationlistbox img {
  width: 50px; /* Adjust image size */
  height: 50px; /* Make it a square */
  margin-right: 15px; /* Space between image and text */
}

.notificationlistbox .text-content {
  display: flex;
  flex-direction: column; /* Stack title and paragraph vertically */
}

.notificationlistbox h4 {
  font-family: "Montserrat", serif;
  font-size: 18px; /* Make the heading a bit larger */
  margin: 0; /* Remove default margin */
  font-weight: 500; /* Bold heading */
  color: #333; /* Dark color for better readability */
}

.notificationlistbox p {
  font-family: "Montserrat", serif;
  font-size: 14px; /* Slightly smaller font for the paragraph */
  margin: 5px 0 0 0; /* Space between heading and paragraph */
  color: #666; /* Lighter color for the paragraph */
}

.Create-Ticket-head1 {
  font-family: "Montserrat", serif;
  font-size: 34px;
  font-weight: 600;
  text-align: left;
}

.Create-Ticket-head2 {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin: 3px 0px;
}

.Create-Ticket-head3 {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  margin: 30px 0px;
}

.create-ticket-label {
  font-family: "Montserrat", serif;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  position: relative;
  top: 10px;
}

@media (max-width: 768px) {
  /* In mobile view, hide the links and profile/avatar */
  .mobile-menu-dash {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100px;
    left: 300px;
    background-color: white;
    color: black;
    width: 200px;
    height: 100vh;
    padding: 10px 20px;
    box-shadow: 20px 30px 30px 0px rgba(128, 128, 128, 0.176);
  }
  .mobile-menu-dash a {
    color: black;

    /* text-align: center; */
  }
  .mobile-menu-dash button {
    color: black;
  }

  .dashnav {
    height: 100px;
  }

  /* .dashnav-right {
    position: absolute;
    right: 10px;
  } */
  .mobile-menu-btn {
    display: flex;
  }

  .dashnav-left {
    display: none;
  }
}
