.contact-form-common {
  padding: 0px 30px;
  width: 93vw;
  margin: 0px auto;

 
}
.contact-form-common h5 {
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  color: #39b5d1;
}

.contact-form-common h6 {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000066;
}

.contact-form-common p {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #000066;
  margin: 5px 0px;
}

.contactBuild {
  background-image: url("../Images/Home/landingbg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 300px;

  /* Flexbox to center content */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center align text */
}

.contactBuild h4 {
  font-family: "Montserrat", serif;
  font-size: 38px;
  font-weight: 700;
  text-align: center;
  color: white;
}
.contactBuild p {
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: white;
}

.contact-form-para {
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;  
  text-align: left;
  position: relative;
  bottom: 10px;
}

.contact-form-main-div {
  position: relative;
  z-index: 1; /* Ensure content is above the background */
}

.contact-form-main-div::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../Images/Home/networkpic.jpg");
  background-size: 100%;
  background-position: top;
  background-repeat: no-repeat;
  opacity: 0.1; /* Set the background opacity */
  z-index: -1; /* Place the background behind the content */
}
.contact-form-main-div p {
  color: #212529;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin: 10px auto;
}

.Contact-grid {
  width: 60vw;
  margin:10px auto 30px;
}
.Contact-grid h4 {
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.20000000298023224px;
  text-align: center;
  color: #000066;
}

.Contact-grid h6 {
  font-family: "Montserrat", serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #070707;
}
.Contact-grid p {
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  text-align: center;
  color: #000066;
}


@media (max-width: 900px) {
  .contact-form-common {
    padding: 10px 30px;
    width: 100vw;
  }

  .Contact-grid h6 {
    line-height:28px;
  }

  .contact-form-common h6 {
    font-family: "Montserrat", serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000066;
  }
}