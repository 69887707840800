/* nav */
.custom-dropdown {
  position: absolute;
  top: 5px;
  cursor: pointer;
  border: 1px solid #000066;
  border-radius: 20px;
  padding: 2px;
}
.custom-dropdown-dash {
  position: absolute;
  top: 5px;
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 20px;
  padding: 2px 10px;
}

.selected-option {
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
}

.selected-option img {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

.options-list {
  position: absolute;
  top:45px;
  left: -10px;
  z-index: 9999;
}

.options-list li {
  padding:10px;
  width: 100px;
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid #959494;
  }

.options-list li:hover {
  background-color: #f0f0f0;
}

.options-list-dash {
  position: absolute;
  top:45px;
  left: -10px;
  z-index: 9999;
}

.options-list-dash li {
  padding: 15px;
  width: 100px;
  display: flex;
  align-items: center;
  background-color: white;
  border: 2px solid #f0f0f0;
}

.options-list-dash li:hover {
  background-color: #f0f0f0;
}



.custom-dropdown:hover .options-list {
  display: block;
}

@media (max-width: 1400px) {

  .custom-dropdown, .custom-dropdown-dash {
    position: relative;
    top: 5px;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 20px;
    padding: 5px;
  }

  .options-list li {
    padding: 15px;
    width: 100px;
    border: none;
    display: flex;
    align-items: center;
    background-color: black;
  }
  .options-list li:hover {
    background-color: black;
  }
}